import { WindowLocation } from '@reach/router';
import { graphql, withPrefix } from 'gatsby';
import { uniq } from 'lodash-es';
import React, { useEffect, memo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { configureAnchors } from 'react-scrollable-anchor';
import { bindActionCreators, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import {
  getPhaseTitleForMap,
  getSuperBlockTitleForMap
} from '../../utils/superblock-map-titles';
import {
  isSignedInSelector,
  userSelector,
  currentChallengeIdSelector,
  signInLoadingSelector,
  completedChallengesSelector,
  userRegistrationStatusSelector,
  courseCodeInfoSelector
} from '../../redux/selectors';
import {
  hardGoTo as navigate,
  checkUserRegistration
} from '../../redux/actions';
import {
  MarkdownRemark,
  AllChallengeNode,
  User,
  CompletedChallenge,
  ChallengeNode,
  CourseCodeDataProps
} from '../../redux/prop-types';
import Accordion from '../../components/ui/Accordion';
import MainContentLayout from '../../components/layouts/main-content-layout';
import {
  calculateAmountOfCompletedChallenges,
  calculateAmountOfCompletedPhases,
  verifyThemeEnable
} from '../../utils/all-challenge-nodes/calculate-challenges';
import { AccessLevel } from '../../utils/enums/access-levels';
import { SuperBlocks } from '../../../../config/certification-settings';
import { firstCheckpoint } from '../Challenges/classic/show';
import { RegistrationResponseProps } from '../../utils/ajax';
import { userCompletedFirstCheckpointChallenge } from '../../utils/general-functions';
import Block from './components/block';
import CertChallenge from './components/cert-challenge';
import SuperBlockIntro from './components/super-block-intro';
import { resetExpansion, toggleBlock } from './redux';
import ContinueProgress from './components/continue-progress';

import './intro.css';

type SuperBlockProp = {
  readonly currentChallengeId: string;
  readonly data: {
    markdownRemark: MarkdownRemark;
    allChallengeNode: AllChallengeNode;
  };
  readonly isSignedIn: boolean;
  readonly signInLoading: boolean;
  readonly location: WindowLocation<{ breadcrumbBlockClick: string }>;
  readonly navigate: (location: string) => void;
  readonly resetExpansion: () => void;
  readonly toggleBlock: (arg0: string) => void;
  readonly user: User;
  readonly completedChallengeIds: string[];
  readonly checkUserRegistration: (data: {
    cpf: string;
    courseCode: string;
  }) => void;
  readonly userRegistrationStatus: RegistrationResponseProps;
  readonly courseCodeInfo: CourseCodeDataProps;
};

type PhaseBlock = {
  phase: string;
  phaseOrder: number;
  block: string;
};

type PhaseInfoProps = PhaseBlock[];

type PhaseMap = {
  [phaseOrder: number]: PhaseInfoProps;
};

type PhaseProps = {
  phaseTitle: string;
  phaseOrder: number;
  blocks: string[];
};

configureAnchors({ offset: -40, scrollDuration: 0 });

const mapStateToProps = (state: Record<string, unknown>) => {
  return createSelector(
    currentChallengeIdSelector,
    isSignedInSelector,
    signInLoadingSelector,
    userSelector,
    completedChallengesSelector,
    userRegistrationStatusSelector,
    courseCodeInfoSelector,
    (
      currentChallengeId: string,
      isSignedIn,
      signInLoading: boolean,
      user: User,
      completedChallenges: CompletedChallenge[],
      userRegistrationStatus: RegistrationResponseProps,
      courseCodeInfoSelector: CourseCodeDataProps
    ) => ({
      currentChallengeId,
      isSignedIn,
      signInLoading,
      user,
      completedChallengeIds: completedChallenges.map(({ id }) => id),
      userRegistrationStatus,
      courseCodeInfoSelector
    })
  )(state);
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      resetExpansion,
      navigate,
      toggleBlock: b => toggleBlock(b),
      checkUserRegistration
    },
    dispatch
  );

const userHasCompletedFirstRegistration = (
  userRegistrationResponse: RegistrationResponseProps | null
) =>
  userRegistrationResponse?.Row &&
  userRegistrationResponse?.Row?.length > 0 &&
  typeof userRegistrationResponse?.Row[0].RA === 'string';

const SuperBlockIntroductionPage = (props: SuperBlockProp) => {
  const {
    signInLoading,
    isSignedIn,
    navigate,
    user,
    completedChallengeIds,
    data: {
      markdownRemark: {
        frontmatter: { superBlock, title, certification }
      },
      allChallengeNode: { edges }
    },
    userRegistrationStatus,
    courseCodeInfo,
    checkUserRegistration
  } = props;

  const [extendedPhase, setExtendedPhase] = useState<number | null>(0);

  useEffect(() => {
    initializeExpandedState();

    setTimeout(() => {
      configureAnchors({ offset: -40, scrollDuration: 400 });
    }, 0);

    return () => {
      configureAnchors({ offset: -40, scrollDuration: 0 });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!signInLoading) {
      if (!isSignedIn) {
        navigate(withPrefix('/learn'));
      }
    }
  }, [signInLoading, isSignedIn, navigate]);

  const i18nTitle = getSuperBlockTitleForMap(superBlock);

  const nodesForSuperBlock = edges.map(({ node }) => node);
  const PhaseInfo: PhaseInfoProps = uniq(
    nodesForSuperBlock.map(({ challenge: { phaseOrder, phase, block } }) => {
      return {
        block,
        phase,
        phaseOrder
      };
    })
  );
  const groupedByPhase: PhaseMap = groupByPhase(PhaseInfo);
  const subarrays: PhaseInfoProps[] = Object.values(groupedByPhase);
  const organizedArray: PhaseProps[] = organizeBlocks(subarrays);

  const getChosenBlock = (): string => {
    const {
      data: {
        allChallengeNode: { edges }
      },
      isSignedIn,
      currentChallengeId,
      location
    }: SuperBlockProp = props;

    // if coming from breadcrumb click
    if (
      location.state &&
      typeof location.state === 'object' &&
      Object.prototype.hasOwnProperty.call(
        location.state,
        'breadcrumbBlockClick'
      )
    ) {
      return location.state.breadcrumbBlockClick;
    }

    // if the URL includes a hash
    if (location.hash) {
      return location.hash.replace('#', '').replace('/', '');
    }

    const edge = edges[0];

    if (isSignedIn) {
      // see if currentChallenge is in this superBlock
      const currentChallengeEdge = edges.find(
        edge => edge.node.challenge.id === currentChallengeId
      );

      return currentChallengeEdge
        ? currentChallengeEdge.node.challenge.block
        : edge.node.challenge.block;
    }

    return edge.node.challenge.block;
  };

  const initializeExpandedState = () => {
    const { resetExpansion, toggleBlock } = props;

    resetExpansion();
    return toggleBlock(getChosenBlock());
  };

  const certChallengeComplete = (allChallenge: ChallengeNode[]) => {
    const { completedChallengeIds }: SuperBlockProp = props;
    const lastChallenge = getLastChallenge(allChallenge);
    return completedChallengeIds.some(
      (completedChallengeId: string) =>
        completedChallengeId === lastChallenge.challenge.id
    );
  };

  const getLastChallenge = (allChallenge: ChallengeNode[]) => {
    return allChallenge[allChallenge.length - 1];
  };

  // group themes by phase
  function groupByPhase(array: PhaseInfoProps): PhaseMap {
    return array.reduce((acc: PhaseMap, obj: PhaseBlock) => {
      const { phaseOrder } = obj;
      if (!acc[phaseOrder]) {
        acc[phaseOrder] = [];
      }
      acc[phaseOrder].push(obj);
      return acc;
    }, []);
  }

  // Organizes the block (themes) names
  function organizeBlocks(array: PhaseInfoProps[]): PhaseProps[] {
    const result: PhaseProps[] = [];

    array.forEach((subarray: PhaseInfoProps) => {
      const { phaseOrder, phase } = subarray[0];
      let blocks = subarray.map((obj: PhaseBlock) => obj.block);
      blocks = [...new Set(blocks)];
      result.push({
        blocks,
        phaseOrder,
        phaseTitle: phase
      });
    });

    return result;
  }

  const challenges: ChallengeNode[] = edges.map(edge => edge.node);

  const verifyPhaseDisabled = (phaseOrder: number, index: number): boolean => {
    if (
      user.access === AccessLevel.TRILHA1 &&
      superBlock !== SuperBlocks.LogicaDeProgramacao
    ) {
      return true;
    }

    if (user.access === AccessLevel.LIMITED && phaseOrder > 0) {
      return true;
    }

    const amountOfCompletedPhases = calculateAmountOfCompletedPhases(
      superBlock,
      challenges,
      completedChallengeIds
    );

    const hasFinishedPreviousPhase = index <= amountOfCompletedPhases;

    if (!hasFinishedPreviousPhase) {
      return true;
    }

    if (user.access === AccessLevel.LimitedNacional) {
      const superOrder = edges[0].node.challenge.superOrder;

      const isPhaseAfterFirstCheckpoint = (): boolean => {
        if (superOrder > firstCheckpoint.superOrder) {
          return true;
        } else if (superOrder === firstCheckpoint.superOrder) {
          if (phaseOrder > firstCheckpoint.phaseOrder) {
            return true;
          } else if (phaseOrder === firstCheckpoint.phaseOrder) {
            return false;
          }
        }
        return false;
      };

      if (
        isPhaseAfterFirstCheckpoint() &&
        !userHasCompletedFirstRegistration(userRegistrationStatus)
      ) {
        return true;
      }
    }

    return false;
  };

  const handlePhaseClick = (index: number) => {
    setExtendedPhase(extendedPhase !== index ? index : null);

    if (
      user.access === AccessLevel.LimitedNacional &&
      index > 3 &&
      userCompletedFirstCheckpointChallenge(completedChallengeIds)
    ) {
      checkUserRegistration({
        cpf: user.cpf,
        courseCode: courseCodeInfo?.courseCodes[1]
      });
    }
  };

  return (
    <MainContentLayout
      title={`${i18nTitle} | DEVstart`}
      showUnlockCard={user.access !== AccessLevel.COMPLETE}
    >
      <SuperBlockIntro superBlock={superBlock} />

      {user.access !== 'blocked' && (
        <ContinueProgress
          completedChallengeIds={completedChallengeIds}
          access={user.access}
          userRegistrationStatus={userRegistrationStatus}
        />
      )}

      <div className='block-ui'>
        {/* phases */}
        {organizedArray.map((phase, index) => {
          const { phaseOrder, phaseTitle, blocks } = phase;

          const amountOfCompletedChallenges =
            calculateAmountOfCompletedChallenges(
              blocks,
              nodesForSuperBlock,
              completedChallengeIds
            );
          const amountOfChallenges = blocks.length;

          const isLocked = verifyPhaseDisabled(phaseOrder, index);
          const showUnlockModal =
            isLocked && user.access === AccessLevel.LIMITED;

          return (
            // phase
            <Accordion
              key={phaseOrder}
              blockTitle={getPhaseTitleForMap(superBlock, phaseTitle)}
              handleBlockClick={() => handlePhaseClick(index)}
              isExpanded={extendedPhase === index}
              progressBarInfo={{
                completedCount: amountOfCompletedChallenges,
                numberChallenges: amountOfChallenges
              }}
              progressText='type3'
              isChecked={amountOfCompletedChallenges === amountOfChallenges}
              isLocked={isLocked}
              showUnlockModal={showUnlockModal}
            >
              {/* themes */}
              {blocks.map((blockDashedName, index) => (
                <div key={blockDashedName} id={`block-${index}`}>
                  {/* theme */}
                  <Block
                    access={user.access}
                    isUnlocked={verifyThemeEnable(
                      nodesForSuperBlock,
                      blockDashedName,
                      user.access,
                      completedChallengeIds
                    )}
                    blockDashedName={blockDashedName}
                    challenges={nodesForSuperBlock.filter(
                      node => node.challenge.block === blockDashedName
                    )}
                    phase={phaseTitle}
                    superBlock={superBlock}
                  />
                </div>
              ))}
            </Accordion>
          );
        })}
        {user.access !== AccessLevel.LimitedNacional &&
          user.access !== AccessLevel.CompleteNacional && (
            <div>
              <CertChallenge
                isCompleted={certChallengeComplete(nodesForSuperBlock)}
                certification={certification}
                superBlock={superBlock}
                title={title}
                user={user}
              />
            </div>
          )}
      </div>
    </MainContentLayout>
  );
};

SuperBlockIntroductionPage.displayName = 'SuperBlockIntroductionPage';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(memo(SuperBlockIntroductionPage)));

export const query = graphql`
  query SuperBlockIntroPageBySlug($slug: String!, $superBlock: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        certification
        superBlock
        title
      }
    }
    allChallengeNode(
      sort: {
        fields: [
          challenge___superOrder
          challenge___order
          challenge___challengeOrder
        ]
      }
      filter: { challenge: { superBlock: { eq: $superBlock } } }
    ) {
      edges {
        node {
          challenge {
            fields {
              slug
              blockName
            }
            id
            block
            challengeType
            title
            superOrder
            order
            superBlock
            dashedName
            phase
            phaseOrder
            phase
          }
        }
      }
    }
  }
`;
